<template>
  <div class="multi-line__header_subTitle">
    <!-- 多个副标题时轮播 -->
    <SwiperSlide
      v-if="carouselTitle?.length > 1"
      :ref="`subTitleSwiper_${analysisCode}`"
      :item-length="carouselTitle?.length"
      :intersectionObserver="true"
      observerLength
      :loop="carouselTitle?.length > 1"
      :autoplay="2000"
      :vertical="true"
      :style="subTitleHeight()"
    >
      <SwiperSlideItem
        v-for="(swiperItem, swiperIndex) in carouselTitle"
        :key="swiperIndex"
        class="multi-line__header_subTitle-item"
      >
        <span class="multi-line__header_subTitle-text">
          {{ swiperItem }}
          <ClientOnly>
            <span
              v-if="subTitleBGColor"
              :key="`carouselTitle_${swiperIndex}`"
              class="multi-line__header_subTitle-bg"
              :style="subTitleBGStyle()"
            ></span>
          </ClientOnly>
        </span>
        <!-- 趋势模版趋势增速词 -->
        <ClientOnly>
          <TrendsBadge
            v-if="carouselTrends && carouselTrends.length"
            :badge="carouselTrends[swiperIndex]"
            :cssRight="cssRight"
            :first-screen="swiperIndex === carouselTitle.length - 1"
          />
        </ClientOnly>
      </SwiperSlideItem>
    </SwiperSlide>
    <div
      v-else
      class="multi-line__header_subTitle-item"
      :style="subTitleHeight()"
    >
      <span class="multi-line__header_subTitle-text">
        {{ carouselTitle?.length ? carouselTitle[0] : subTitle }}
        <ClientOnly>
          <span
            v-if="subTitleBGColor"
            key="carouselTitle"
            class="multi-line__header_subTitle-bg"
            :style="subTitleBGStyle()"
          >
          </span>
        </ClientOnly>
      </span>
      <!-- 单个趋势模版趋势增速词 -->
      <ClientOnly>
        <TrendsBadge
          v-if="carouselTrends && carouselTrends.length"
          :badge="carouselTrends[0]"
          :cssRight="cssRight"
          :first-screen="false"
        />
      </ClientOnly>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import SwiperSlide from 'public/src/pages/components/swiperSlide/SwiperSlide'
import SwiperSlideItem from 'public/src/pages/components/swiperSlide/SwiperSlideItem'
import TrendsBadge from './TrendsBadge'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'

export default defineComponent({
  name: 'MultiLineSubTitle',
  components: {
    ClientOnly,
    BaseImg,
    SwiperSlide,
    SwiperSlideItem,
    TrendsBadge,
  },
  props: {
    ...commonProps,
    cssRight: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: '0.2933rem', // 11px
    },
    // 埋点analysisCode，用于获取唯一元素
    analysisCode: {
      type: String,
      default: '',
    },
  },
  data() {
    const {
      subTitle,
      subTitleBGColor,
    } = this.propData?.props?.metaData || {}
    return {
      subTitle,
      subTitleBGColor,
    }
  },
  computed: {
    carouselTitle() {
      return this.propData?.props?.metaData?.carouselTitle || []
    },
    carouselTrends() {
      return this.propData?.props?.metaData?.carouselTrends || []
    },
  },
  methods: {
    subTitleBGStyle() {
      return {
        background: `linear-gradient(270deg, ${hexToRGBA(this.subTitleBGColor, 0, )} 0%, ${hexToRGBA(this.subTitleBGColor, 0.3)}`,
      }
    },
    subTitleHeight() {
      const fontSize = this.fontSize
      let height = parseFloat(fontSize) * 37.5 + 2 // 加2px间隙高度
      height = (height / 37.5) + 'rem'
      return { 
        height,
        lineHeight: height
      } 
    },
  },
  watch: {
    carouselTitle: {
      handler(newVal, oldVal) {
        if (!newVal?.length || !oldVal?.length) return
        if (newVal?.length > 1) {
          nextTick(()=>{
            // 轮播元素更新
            const subTitleSwiper = this.$refs[`subTitleSwiper_${this.analysisCode}`]
            subTitleSwiper.onUpdate()
          })
        }
      },
      immediate: true
    },
  },
})
</script>

<style lang="less">
.multi-line__header_subTitle {
  font-weight: 400;
  display: flex;
  align-items: center;
  &-item {
    display: flex;
    align-items: center;
    overflow: hidden;
    .product-bff__badge {
      flex-shrink: 0;
      position: relative;
      border-radius: 20px;
      margin-left: 0.1067rem;
      overflow: hidden;
    }
    .product-bff__badgetext {
      padding: 0 3px;
    }
  }
  &-text {
    position: relative;
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-bg {
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 1px 0 0 2px;
    transform-origin: bottom left;
    transform: skew(-45deg) translateX(-1px); /* 单侧斜边 */
  }
}
</style>
