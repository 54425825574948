<!-- 1/2、1/4 、整行组件标题区 -->
<template>
  <div
    v-tap="getTitleAnalysis({ id: '2-22-2' })"
    v-expose="getTitleAnalysis({ id: '2-22-1' })"
    @click="handleHeaderClick"
    ref="multiLineHeader"
    class="multi-line__header"
    :style="[
      !isQuarterContainer // 非1/4，根据ui搞，左右间距微调
        ? {
            paddingLeft: cssRight ? 'unset' : '0.0533rem',
            paddingRight: cssRight ? '0.0533rem' : 'unset',
          }
        : null,
    ]"
  >
    <!-- 主标题 -->
    <div
      class="multi-line__header_top"
      :style="{ color: titleColor }" 
      :class="{
        'multi-line__header_single': isSingleContainer, // 整行标题区
        'multi-line__header_notSingle-subTitle': !isSingleContainer && subTitle, // 非整行，且存在副标题，小间距
        'multi-line__header_hide-logo': isSingleContainer && pindaoSkin, // 命中频道换肤时，优先隐藏大促logo
      }"
    >
      <div
        class="multi-line__header_mainTitle"
        ref="mainTitle"
      >
        <p
          v-if="showTitleText"
          class="multi-line__header_mainTitle-content"
          :style="titleCommonStyle"
          ref="mainTitleText"
        >
          {{ title }}
        </p>
        <BaseImg
          ref="mainTitleImg"
          v-if="showTitleImage"
          fit="cover"
          :style="titleImageSize()"
          :img-src="titleImage?.src"
          :placeholder="{
            width: getTitleImgWidth(),
            height: 16,
          }"
          :ratio="titleImage?.ratio || 1"
          :position="cssRight ? '100% 50%' : '0% 50%'"
          class="not-fsp-element"
          :img-design-width="getTitleImgWidth() * 3"
          :first-screen="isFirstPage"
        />
        <!-- 整行、1/2 大促销logo -->
        <ClientOnly>
          <BaseImg
            ref="mainTitlePromotion"
            v-if="!isQuarterContainer && bigSaleLogo?.src"
            fit="cover"
            :style="bigSaleLogoSize()"
            :img-src="bigSaleLogo?.src"
            :placeholder="{
              width: getTitlebigSaleWidth(),
              height: 16,
            }"
            :ratio="bigSaleLogo.ratio"
            :position="cssRight ? '100% 50%' : '0% 50%'"
            class="not-fsp-element multi-line__header_promotion"
            :img-design-width="getTitlebigSaleWidth() * 3"
            :first-screen="isFirstPage"
          />
        </ClientOnly>
        <SPopover
          v-if="showPopover"
          v-model="isTipsPopoverShow"
          :placemen="tipsPopoverPlacement"
          theme="dark"
          show-close-icon
          outside-close
          fix-disappear-position
          :prop-style="tipsPopoverPropsStyle"
          custom-class="super-deal__tips"
          @click.stop
        >
          <span>
            {{ srpFullDesc }}
          </span>
    
          <template #reference>
            <SuiIconDoubt
              class="purchase-coupon-banner__current-status-info-tips-icon"
              :style="{
                color: '#767676',
                marginBottom: '2px',
                marginLeft: '4px',
                transform: 'rotate(180deg)'           
              }"
              width="14"
              height="14"
            />
          </template>
        </SPopover>
      </div>
      <!-- 整行副标题 -->
      <ClientOnly>
        <!-- 整行店铺模板无副标题 showRightIcon -->
        <SubTitleComp 
          ref="subTitle"
          v-if="subTitle && isSingleContainer && showRightIcon"
          :style="[subTitleStyle(), { marginBottom: 0 }]"
          :css-right="cssRight"
          :prop-data="propData"
          :font-size="subTitleStyle()?.fontSize"
          :analysis-code="analysisCode"
        />
        <sui_icon_more_right_12px
          v-if="showRightIcon"
          size="12px"
          :style="[isSingleContainer ? { paddingRight: '0.1067rem' } : {}]"
          :is-rotate="cssRight"
        />
      </ClientOnly>
    </div>
    <!-- 1/2、1/4 副标题 -->
    <SubTitleComp 
      v-if="subTitle && !isSingleContainer"
      :style="subTitleStyle()"
      :css-right="cssRight"
      :prop-data="propData"
      :font-size="subTitleStyle()?.fontSize"
      :analysis-code="analysisCode"
    />
  </div>
</template>

<script>
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent, nextTick } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import { TitleType, CompSize, CompTemplateType } from '../../constants'
import { sui_icon_more_right_12px, sui_icon_caution_12px_2 as SuiIconDoubt } from '@shein-aidc/icon-vue3'
import SubTitleComp from './SubTitle'
import mixin from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { mapGetters } from 'vuex'
import { hexToRGBA } from 'public/src/pages/components/ccc/common/utils.js'
import { SPopover } from '@shein-aidc/sui-popover/mobile'
import { windowLinstnerScrollEventInstance } from 'public/src/services/expose/index.js'

export default defineComponent({
  name: 'MultiLineHeader',
  directives: {
    expose,
    tap,
  },
  components: {
    ClientOnly,
    BaseImg,
    sui_icon_more_right_12px,
    SubTitleComp,
    SPopover,
    SuiIconDoubt,
  },
  mixins: [mixin],
  props: {
    ...commonProps,
    cssRight: {
      type: Boolean,
      default: false,
    },
    productsList: {
      type: Array,
      default: () => [],
    },
    isFirstPage: {
      type: Boolean,
      default: false,
    },
    // 容器索引
    containerIndex: {
      type: Number,
      default: 0,
    },
    // 置顶商品abt
    homeAdpNum: {
      type: Number,
      default: 0,
    },
    // 是否使用频道所对应的cate_id
    useFrontCateId: {
      type: Boolean,
      default: false,
    },
    // 不贵落地页早期tmg请求标志
    isEarlyRequest0903: {
      type: Boolean,
      default: false,
    },
    // 埋点analysisCode
    analysisCode: {
      type: String,
      default: '',
    },
    // 趋势落地页参数判断
    earlyTmgRequest1210: {
      type: Boolean,
      default: false,
    },
    pindaoSkin: {
      type: String,
      default: '',
    },
    pindaoTrendsLook: {
      type: String,
      default: '',
    },
  },
  data() {
    let {
      metaData: {
        templateType,
        componentSize,
        title,
        subTitle,
        titleType,
        titleImage,
        titleColor,
        bigSaleLogo,
        titleTextSize,
        subTitleTextSize,
        mallCodes,
        srpFullDesc, // 不贵模板 增加建议零售价提示信息
      } = {},
      markMap = {},
    } = this.propData?.props || {}
    const { height = 0, width = 0 } = titleImage || {}
    if (titleImage?.src) {
      titleImage.src = titleImage.src.replace('.webp', '.png')
      titleImage.ratio = width / height || 1
    }
    if (bigSaleLogo) {
      const { height: bigSaleHeight = 0, width: bigSaleWidth = 0 } =
        bigSaleLogo || {}
      if (bigSaleLogo?.src) {
        bigSaleLogo.src = bigSaleLogo.src.replace('.webp', '.png')
        bigSaleLogo.ratio = bigSaleWidth / bigSaleHeight || 1
      }
    }
    componentSize = Number(componentSize)
    const isSingleContainer = componentSize == CompSize.full // 是否单行
    const isQuarterContainer = componentSize == CompSize.quarter // 是否1/4
    const showRightIcon = componentSize != CompSize.quarter && templateType != CompTemplateType.shop // 1/4和店铺不展示右侧箭头
    const showTitleImage = titleType === TitleType.image && titleImage?.src // 是否展示标题图片
    const showTitleText = titleType === TitleType.text && title // 是否展示标题文字
    return {
      isTipsPopoverShow: false,
      tipsPopoverPlacement: 'top',
      tipsPopoverPropsStyle: { width: '5rem', zIndex: 14 },
      srpFullDesc,
      markMap,
      templateType,
      componentSize,
      title,
      subTitle,
      titleType,
      titleImage,
      titleColor, // 标题字体颜色，包括箭头
      bigSaleLogo,
      titleTextSize,
      subTitleTextSize,
      mallCodes,
      isSingleContainer,
      showRightIcon,
      isQuarterContainer,
      showTitleImage,
      showTitleText,
    }
  },
  computed: {
    ...mapGetters('config_index', ['channelId', 'topTabList']),
    tabIndex() {
      return this.topTabList?.findIndex(tab => tab.channelId === this.channelId) ?? ''
    },
    titleCommonStyle() {
      let fontSize = '0.3467rem'
      if (this.titleTextSize && !this.checkSizeUnit(this.titleTextSize)) {
        fontSize = this.titleTextSize / 37.5 + 'rem'
      }
      return {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize,
      }
    },
    subTitleColor() {
      return hexToRGBA(this.titleColor, 0.8)
    },
    cate() {
      switch (this.templateType) {
        case CompTemplateType.superDeals: {
          let categoryIds = ''
          if (this.useFrontCateId) {
            // 前台类目 id
            categoryIds = this.context?.curChannelInfo?.tspNodeIds || ''
          } else {
            categoryIds = this.context?.curChannelInfo?.realCateIds || ''
          }
          return {
            markMap: this.markMap,
            hrefType: 'thriftyFind',
            categoryIds,
            nodeId: this.context?.curChannelInfo?.tspNodeIds || '',
            hrefTarget: this.context?.curChannelInfo?.tspNodeIds || '',
          }
        }
        case CompTemplateType.trend:
          return {
            ...this.metaData,
            markMap: this.markMap,
            hrefType: 'trendsChannel',
            hrefTarget: this.context?.curChannelInfo?.realCateIds || '',
          }
        case CompTemplateType.brand:
          return {
            ...this.metaData,
            markMap: this.markMap,
            hrefType: 'brandZone',
          }
        case CompTemplateType.shop:
          return {
            markMap: this.markMap,
            hrefType: 'storeHome',
            hrefTarget: 'noJump',
            categoryIds: this.context?.curChannelInfo?.realCateIds || '',
          }
        default:
          return {
            ...this.metaData,
            markMap: this.markMap,
          }
      }
    },
    showPopover(){
      return this.templateType ==='superDeals' && this.srpFullDesc
    }
  },
  mounted() {
    nextTick(() => {
      this.checkStyleLimit()
    })
    this.listentId = windowLinstnerScrollEventInstance.listen(() => {
      this.isTipsPopoverShow = false
      this.getTipsPlacement()
    })
  },
  unmounted() {
    this.listenId && windowLinstnerScrollEventInstance.unListen(this.listenId)
  },
  methods: {
    getTipsPlacement(){
      if(!this.showPopover){
        return
      }
      const container = this.$refs?.multiLineHeader?.parentNode
      if (!container) return
    
      const { top, bottom } = container?.getBoundingClientRect()
      const { innerHeight } = window
      if (top < innerHeight / 2) {
        this.tipsPopoverPlacement = 'bottom'
      } else if (bottom > innerHeight / 2) {
        this.tipsPopoverPlacement = 'top'
      }
    },
    checkSizeUnit(size) {
      return /(px|em|rem|vh|vw|vmin|vmax|%)$/.test(size)
    },
    // 标题区埋点
    getTitleAnalysis({ id }) {
      return this.getAnalysisData(
        id,
        {
          item: {
            ...this.cate,
            item_loc: this.containerIndex + 1,
            component_area: this.componentSize, // 组件面积
            template: this.templateType, // 组件模板
          },
          index: this.index,
          useBffApi: true,
        },
        `${this.analysisCode}_title_code`,
      )
    },
    getTitleImgWidth() {
      return 16 * Number(this.titleImage?.ratio) + 1 // 适当增加1px的宽度，避免图片显示不全
    },
    getTitlebigSaleWidth() {
      return 16 * Number(this.bigSaleLogo?.ratio) + 1 // 适当增加1px的宽度，避免图片显示不全
    },
    // 标题图大小
    titleImageSize() {
      return {
        width: this.getTitleImgWidth() / 37.5 + 'rem',
        height: '.4267rem',
      }
    },
    // 大促大小
    bigSaleLogoSize() {
      return {
        width: this.getTitlebigSaleWidth() / 37.5 + 'rem',
        height: '.4267rem',
      }
    },
    subTitleStyle() {
      let fontSize = '0.2933rem'
      if (this.subTitleTextSize && !this.checkSizeUnit(this.subTitleTextSize)) {
        fontSize = this.subTitleTextSize / 37.5 + 'rem'
      }
      // 存在副标题
      if (this.subTitle) {
        return {
          ...this.titleCommonStyle,
          marginBottom: '.1067rem',
          fontSize,
          color: this.subTitleColor
        }
      }
      return {}
    },
    handleHeaderClick() {
      switch (this.templateType) {
        case CompTemplateType.superDeals:
          // 不贵跳转
          this.handleSuperDealsJump()
          break
        case CompTemplateType.trend:
          //趋势跳转
          this.handleTrendJump()
          break
        case CompTemplateType.brand:
        case CompTemplateType.category:
        case CompTemplateType.custom:
          this.handleOtherTemplateJump(this.productsList)
          break
        case CompTemplateType.shop:
          // 时尚店铺标题区不跳转,不做处理
          break
        default:
          break
      }
    },
    handleOtherTemplateJump(productList) {
      const params = {
        item: {
          ...this.metaData,
          item_loc: `${this.index}_${this.containerIndex}`,
          adp: '',
        },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: this.containerIndex,
        isBff: true,
      }
      // cccLink from mixin
      let url = this.cccLink.getFullLink(params)

      const goodsIdList = productList
        ?.slice(0, this.homeAdpNum)
        ?.map(i => i.goods_id)
      const goodsIds = goodsIdList.join(',') || ''

      if (this.metaData.hrefType !== 'webLink') {
        const adpStr = `adp=${goodsIds}`
        url = url.indexOf('?') > -1 ? `${url}&${adpStr}` : `${url}?${adpStr}`
      }
      this.jumpLink({ url, hrefType: this.metaData.hrefType })
    },
    // 跳转到不贵频道
    handleSuperDealsJump() {
      const { hrefType, hrefTarget } = this.cate
      const params = {
        item: {
          ...this.metaData,
          hrefType,
          hrefTarget,
          item_loc: `${this.index}_${this.containerIndex}`,
          adp: '',
        },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: this.containerIndex,
        isBff: true,
      }
      // cccLink from mixin
      let url = this.cccLink.getFullLink(params)
      // 列表需要的参数
      const listView =
        window?.localStorage.getItem('super_deals_product_list_view') || ''
      const productsList = this.productsList.slice(0, this.homeAdpNum)
      let flashIds, superIds
      // 组装数据
      superIds = [
        ...productsList
          .filter(i => i.type != 'flashSale')
          ?.map(i => i.goods_id),
      ].join(',')
      flashIds = productsList
        .filter(i => i.type == 'flashSale')
        ?.map(i => i.goods_id)
        .join(',')
      let nodeId = '',
        realCateIds = ''
      switch (Number(this.metaData.categoryType)) {
        case 1:
          // 选中“默认”或“跟随频道”时，从频道获取前台类目；
          nodeId = this.context?.curChannelInfo?.tspNodeIds
          realCateIds = this.context?.curChannelInfo?.realCateIds || ''
          break
        case 3:
          // 选中“前台类目”时，优先使用组件配置的前台类目。前台类目为空时，从频道获取前台类目
          nodeId =
            this.metaData.frontCateIds ||
            this.context?.curChannelInfo?.tspNodeIds ||
            ''
          realCateIds =
            this.metaData.categoryIds ||
            this.context?.curChannelInfo?.realCateIds ||
            ''
          break
        // 选中“全品类”时，前台类目传空，获取全部商品
      }
      const adpStr = `adp=${superIds}&flash_adp=${flashIds}&cat_id=${realCateIds}&node_id=${nodeId}&pagefrom=page_home&list_view=${listView}`
      url = url.indexOf('?') > -1 ? `${url}&${adpStr}` : `${url}?${adpStr}`
      url = `${url}&eventTimestamp=${new Date().getTime()}`
      if (this.isEarlyRequest0903) {
        url = `${url}&isEarlyRequest0903=1`
      }
      if (this.mallCodes) {
        url = `${url}&mall_code=${this.mallCodes}`
      }
      this.jumpLink({
        url,
        hrefType: 'super-deals',
      })
    },
    // 跳转到趋势频道
    handleTrendJump() {
      let query = {
        page_from: [
          'page_home',
          this.tabIndex !== '' ? this.tabIndex + 1 : '-',
          this.context?.curChannelInfo?.tabName || '-',
          'FLEXIBLE_LAYOUT_FOR_SALEZONE',
        ].join('`'),
      }
      // 增加真实分类id
      this.context?.curChannelInfo?.realCateIds &&
        (query.catId = this.context?.curChannelInfo?.realCateIds || '')
      const productsList = this.productsList.slice(0, this.homeAdpNum)
      let arrList = productsList
        ?.filter(item => item?.goods_id)
        ?.map(item => `${item?.homeTrends?.contentCarrierId}_${item?.goods_id}`)
      if (arrList?.length) {
        query.contentCarrierId_adp = arrList.join(',')
      }
      const { hrefType, hrefTarget } = this.cate
      const params = {
        item: {
          ...this.metaData,
          hrefType,
          hrefTarget,
          item_loc: `${this.index}_${this.containerIndex}`,
          adp: '',
        },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: this.containerIndex,
        isBff: true,
      }
      // cccLink from mixin
      let url = this.cccLink.getFullLink(params)
      let queryString = Object.keys(query)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`,
        )
        .join('&')
      url =
        url.indexOf('?') > -1
          ? `${url}&${queryString}`
          : `${url}?${queryString}`
      if (!url || url.indexOf('javascript:;') > -1) return
      if (this.earlyTmgRequest1210) {
        url = `${url}&earlyTmgRequest1210=1`
      }
      this.jumpLink({ url, hrefType: this.cate?.hrefType })
    },
    // 整行标题区样式极值情况时，隐藏部分内容
    checkStyleLimit() {
      if (!this.isSingleContainer) return
      if (!this.pindaoSkin && !this.pindaoTrendsLook) { // 非频道换肤，优先展示大促logo
        const subTitleEl = this.$refs?.subTitleEl
        const subTitleWidth = subTitleEl?.offsetWidth || 0
        if (subTitleEl && subTitleWidth < 25) {// 副标题宽度小于25px时，隐藏副标题
          subTitleEl.style.display = 'none'
        }
      } else { // 频道换肤，优先展示不标题
        const mainTitleWidth = this.$refs?.mainTitle?.offsetWidth || 0
        const mainTitleTextWidth = this.$refs?.mainTitleText?.offsetWidth || 0
        const mainTitleImgWidth = this.$refs?.mainTitleImg?.offsetWidth || 0
        const mainTitlePromotion = this.$refs?.mainTitlePromotion
        const mainTitlePromotionWidth = mainTitlePromotion?.offsetWidth || 0
        if (
          mainTitlePromotion &&
          mainTitleWidth < mainTitleTextWidth + mainTitleImgWidth + mainTitlePromotionWidth
        ) {// 大促logo换行时，隐藏大促logo。仅标题遮盖隐藏而没有添加display：none时，副标题宽度会被压缩
          mainTitlePromotion.style.display = 'none'
        }
      }
    },
  },
})
</script>

<style lang="less">
.super-deal__tips {
  .sui-popover__close-arrow {
    font-size: 14px;
    color: #c1c1c1;
  }
}
.multi-line__header {
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  &_top {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .1067rem;
  }
  &_notSingle-subTitle {
    margin-bottom: .0533rem; // 1/2、1/4副标题与商品区间距
  }
  &_mainTitle {
    display: flex;
    height: 16px;
    max-width: 100%;
    flex-shrink: 0;
  }
  &_promotion.base-img {
    display: inline-block;
    margin-left: 0.1067rem; // 4px
    .base-img__inner {
      object-fit: contain;
    }
  }
  &_mainTitle-content {
    font-size: 0.3467rem;
    font-weight: 700;
    line-height: 0.4136rem;
    height: 0.4267rem;
    display: inline-block;
    max-width: 100%;
  }
  &_single {
    &-main {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-shrink: 0;// 优先展示主标题，避免被压缩
    }
    .multi-line__header_subTitle {
      flex-grow: 1; // 副标题占满剩余空间
      justify-content: flex-end; // 副标题靠右
      text-align: right;
      padding-left: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &-item {
        justify-content: flex-end; // 整行副标题轮博时，靠右
      }
    }
  }
  &_hide-logo {
    // 命中频道换肤时，优先隐藏大促logo
    .multi-line__header_mainTitle {
      flex-shrink: 1;
    }
  }
}
</style>
