<template>
  <div 
    class="product-item"
    :class="{'with-padding': type === 'horizontal' && !isSmallItem, 'no-padding': options?.noPadding, 'dynamic-comp': dynamicComp }"
  >
    <!-- placeholder 占位 -->
    <template v-if="type === 'horizontal'">
      <div class="product-item__horizontal">
        <div
          v-for="(item, i) in num"
          :key="i"
          :class="[isHorizontalSlideChanelDynamicComp ? 'product-item__horizontal-dynamic-item' : 'product-item__horizontal-item', { 'product-item__left-flash' : isLeftFlashSale && i === 0 }]"
          :style="[isSmallItem ? horizontalItemStyle : {}]"
        >
          <div
            class="product-item__img"
            :style="isHorizontalSlideChanelDynamicComp ? { paddingBottom: '123%' } : {}"
          ></div>
          <div
            v-if="!hideFields.includes('price')"
            class="product-item__price"
          ></div>
          <div class="product-item__tag-block"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="product-item__vertical">
        <div 
          v-for="(item, i) in num"
          :key="i"
          :class="[`product-item__row${column}`]"
          :style="verticalItemStyle"
        >
          <div 
            class="product-item__img"
          ></div>
          <div
            v-if="!hideFields.includes('price')"
            class="product-item__price"
          ></div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PlaceholderItem',
  props: {
    // 列表样式
    type: {
      type: String,
      default: 'horizontal'
    },
    brand: {
      type: String,
      default: 'shein'
    },
    // 占位数量
    num: {
      type: Number,
      default: 4
    },
    // 纵向列表使用
    column: {
      type: Number,
      default: 2
    },
    // 横向列表使用
    options: {
      type: Object,
      default: () => ({})
    },
    // 横向列表使用, 是否是小图
    isSmallItem: {
      type: Boolean,
      default: false
    },
    // 横向列表使用
    horizontalItemWidth: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: [Number, String],
      default: 76
    },
    hideFields: {
      type: Array,
      default: () => [],
    },
    dynamicComp: { // 动态组件4.5图
      type: Boolean,
      default: false
    },
    verticalItemStyle: {
      type: Object,
      default: () => ({})
    },

    horizontalIStyle: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
  // 自定义horizontal模式下item的大小
    horizontalItemStyle() {
      if (this.horizontalItemWidth) {
        const width = this.pxToVw(this.horizontalItemWidth)
        return {
          width,
          minWidth: 'auto',
          ...this.horizontalIStyle
        }
      } else {
        return {}
      }
    },
    isLeftFlashSale() {
      const { isLeftFlashSale = false } = this.options
      return isLeftFlashSale
    },
    // 是否为横滑频道入口组件
    isHorizontalSlideChanelDynamicComp() {
      const { isHorizontalSlideChanelDynamicComp = false } = this.options
      return isHorizontalSlideChanelDynamicComp
    }
  },
  methods: {
    pxToVw(px) {
      if (!px) return
      return Number(px) / (375 / 100) + 'vw'
    },
  },
})
</script>
<style lang="less" scoped>
.product-item {
  position: relative;
  width: 100%;
  &.with-padding {
    padding-left: 0.27rem;
  }
  &.no-padding {
    padding-left: 0;
  }
  &__horizontal {
    display: flex;
    overflow: hidden;
  }
  &__horizontal-item {
    min-width: 2.96rem;
    margin-right: 4px;
    border: 2px solid transparent;
  }
  &__horizontal-dynamic-item {
    min-width: 2.72rem;
    margin-right: 2px;
    border: 2px solid transparent;
  }
  &__left-flash {
    .product-item__img {
      padding-bottom: 167.45532%;
      margin-bottom: 0 !important;  /* stylelint-disable-line */
    }
    .product-item__price {
      display: none;
    }
  }

  &__vertical {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__img {
    background-color: @sui_color_gray_weak1a;
    padding-bottom: 133%;
  }
  &__price {
    width: 60%;
    height: .49637681rem;
    background-color: @sui_color_gray_weak1a;
    margin-top: 0.2133rem;
  }
  &__tag-block {
    padding-top: 0.11rem;
  }
  &__row3 {
    width: 31.91%;
    margin-bottom: .64rem;
  }
  &__row2 {
    width: 48.29%; // 默认双图的尺寸
    margin-bottom: .64rem;
  }
  &.dynamic-comp { // 动态组件4.5图
    padding-left: 4px;
    .product-item__horizontal-item {
      min-width: 2.1rem;
    }
    .product-item__img {
      min-width: 100%;
    }
  }
}
</style>
