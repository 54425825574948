<template>
  <div class="product-bff__badge">
    <div
      class="product-bff__badgetext"
      :style="badgeTextStyle(badge)"
    >
      <BaseImg
        v-if="badge?.icon?.src"
        fit="cover"
        :placeholder="{
          width: badge?.icon?.width,
          height: badge?.icon?.height,
        }"
        :img-src="webpCheck(badge?.icon?.src)"
        class="not-fsp-element"
        :img-design-width="Number(badge?.icon?.width) * 2"
        :first-screen="firstScreen"
        :style="itemIconStyle(badge)"
      />
      <!-- 角标通常为折扣，折扣镜像时，bff已处理为 %35-，所以默认角标文案不旋转，保证 %35- 显示，特殊需旋转的可以端处理配置textRotate -->
      <span
        class="product-bff__badgetext_content"
        :class="{
          'product-bff__badgetext_rotate': cssRight && badge?.textRotate,
        }"
      >
        {{ badge?.text }}
      </span>
    </div>
    <BaseImg
      v-if="badge?.bgImage?.src"
      fit="cover"
      :placeholder="{
        width: badge?.bgImage?.width,
        height: badge?.bgImage?.height,
      }"
      :img-src="webpCheck(badge?.bgImage?.src)"
      class="not-fsp-element product-bff__badgebg"
      :img-design-width="Number(badge?.bgImage?.width) * 2"
      :first-screen="firstScreen"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'

export default defineComponent({
  name: 'MultiLineTrendsBadge',
  components: {
    BaseImg,
  },
  props: {
    badge: {
      type: Object,
      default: {},
    },
    cssRight: {
      type: Boolean,
      default: false,
    },
    // 趋势模板角标，写死轮播时最后一项不懒加载，避免偶现的图标未懒加载替换图片，导致闪烁
    firstScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const {
      carouselTitle,
      carouselTrends,
    } = this.propData?.props?.metaData || {}
    return {
      carouselTitle,
      carouselTrends,
    }
  },
  methods: {
    checkSizeUnit(size) {
      return /(px|em|rem|vh|vw|vmin|vmax|%)$/.test(size)
    },
    badgeTextStyle(badge) {
      let fontSize = '0.24rem'
      if (badge.textSize && !this.checkSizeUnit(badge.textSize)) {
        fontSize = badge.textSize / 37.5 + 'rem'
      }
      return {
        fontSize,
        fontWeight: Number(badge?.isInBold) ? '700' : '400',
        color: badge.textColor || '#000',
      }
    },
    itemIconStyle(badge) {
      let width = '0.24rem'
      let height = '0.24rem'
      if (badge.icon?.width) {
        if (badge.icon?.width && !this.checkSizeUnit(badge.icon?.width)) {
          width = badge.icon.width / 37.5 / 3 + 'rem' // 缩小三倍
        }
      } else {
        if (badge.textSize && !this.checkSizeUnit(badge.textSize)) {
          width = badge.textSize / 37.5 / 3 + 'rem' // 缩小三倍
        }
      }
      if (badge.icon?.height) {
        if (badge.icon?.height && !this.checkSizeUnit(badge.icon?.height)) {
          height = badge.icon.height / 37.5 / 3 + 'rem' // 缩小三倍
        }
      } else {
        if (badge.textSize && !this.checkSizeUnit(badge.textSize)) {
          height = badge.textSize / 37.5 / 3 + 'rem' // 缩小三倍
        }
      }
      return {
        width,
        height,
      }
    },
    webpCheck(src) {
      return src?.replace('.webp', '.png')
    },
  },
})
</script>

<style lang="less" scoped>
.product-bff__badge {
  flex-shrink: 0;
  position: relative;
  border-radius: 20px;
  margin-left: 0.1067rem;
  overflow: hidden;
}
.product-bff__badgetext {
  padding: 0 3px;
}
</style>
