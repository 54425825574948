<template>
  <div class="multi-line__single_content">
    <slot name="multiLineBg"></slot>
    <MultiLineHeader
      :css-right="cssRight"
      :prop-data="propData"
      :is-first-page="isFirstPage"
      :index="index"
      :container-index="containerIndex"
      :cate-links="cateLinks"
      :products-list="productsList"
      :home-adp-num="homeAdpNum"
      :use-front-cate-id="useFrontCateId"
      :is-early-request0903="isEarlyRequest0903"
      :early-tmg-request1210="earlyTmgRequest1210"
      :scene-data="sceneData"
      :context="context"
      :analysis-code="analysisCode"
      :pindaoSkin="pindaoSkin"
      :pindaoTrendsLook="pindaoTrendsLook"
    />
    <VirtualSwiper
      v-if="productsList.length > 0"
      ref="singleContainerSwiperRef"
      destroy-on-disconnected="false"
      class="single-container__swiper"
      :options="options"
      :source-data="productsList"
      :initVirtualNum="Math.ceil(productsNum)"
    >
      <template #default="{ virtualData }">
        <swiper-slide
          v-for="(item, itemIndex) in virtualData"
          :key="`${item.goods_id}_${itemIndex}`"
          class="single-container__swiper_slide"
          :style="{
            width: `calc(100% / ${productsNum})`,
          }"
        >
          <component
            :is="currentComponent"
            :ref="`${templateType}-1-${containerIndex}`"
            :key="`${item.goods_id}_${itemIndex}`"
            :index="index"
            :product-item-config="productItemConfig"
            :css-right="cssRight"
            :context="context"
            :language="language"
            :prop-data="propData"
            :product-item="item"
            :product-item-index="itemIndex"
            :container-index="containerIndex"
            :is-first-page="isFirstPage"
            :show-view-more="itemIndex === productsList.length - 1"
            :view-more-text="viewMoreText"
            :scene-data="sceneData"
            :products-list="productsList"
            :cate-links="cateLinks"
            :home-discount-new="homeDiscountNew"
            :show-strengthen-price="showStrengthenPrice"
            :home-adp-num="homeAdpNum"
            :use-front-cate-id="useFrontCateId"
            :products-num="productsNum"
            :is-early-request0903="isEarlyRequest0903"
            :early-tmg-request1210="earlyTmgRequest1210"
            :crop-rate="cropRate"
            :suggested-sale-type="suggestedSaleType"
            :analysis-code="analysisCode"
            :pindaoSkin="pindaoSkin"
            :pindaoTrendsLook="pindaoTrendsLook"
          />
        </swiper-slide>
      </template>
    </VirtualSwiper>
    <PlaceholderItem
      v-else
      :num="Math.floor(productsNum)"
      :brand="brand"
      :is-small-item="true"
      :horizontal-item-width="85.38"
    />
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { CompTemplateType } from '../constants.js'
import PlaceholderItem from 'public/src/pages/components/ccc/base/PlaceholderItem'
import VirtualSwiper from 'public/src/pages/components/ccc/base/swiper/VirtualSwiper'
import MultiLineHeader from '../components/headers/Index'
import Shop from '../components/Shop'
import OtherTemplate from '../components/OtherTemplate'
import { register } from 'swiper/element'

if(typeof window !== 'undefined'){
  register()
}
export default defineComponent({
  name: 'SingleContainer',
  components: {
    VirtualSwiper,
    MultiLineHeader,
    Shop,
    OtherTemplate,
    PlaceholderItem,
  },
  props: {
    ...commonProps,
    // 商品项配置
    productItemConfig: {
      type: Object,
      default: () => {}
    },
    cssRight: {
      type: Boolean,
      default: false
    },
    isFirstPage: {
      type: Boolean,
      default: true
    },
    // 容器索引
    containerIndex: {
      type: Number,
      default: 0,
    },
    // 置顶商品abt
    homeAdpNum: {
      type: Number,
      default: 0
    },
    // 不贵是否加强价格
    showStrengthenPrice: {
      type: Boolean,
      default: false
    },
    // 是否使用频道所对应的cate_id
    useFrontCateId: {
      type: Boolean,
      default: false
    },
    cropRate: {
      type: String,
      default: '3-4'
    },
    // 不贵落地页早期tmg请求标志
    isEarlyRequest0903: {
      type: Boolean,
      default: false
    },
    // abt控制是否展示折扣标签，合规价
    homeDiscountNew: {
      type: String,
      default: ''
    },
    // 合规类型
    suggestedSaleType: {
      type: String,
      default: ''
    },
    // 趋势落地页参数判断
    earlyTmgRequest1210: {
      type: Boolean,
      default: false
    },
    pindaoSkin: {
      type: String,
      default: ''
    },
    pindaoTrendsLook: {
      type: String,
      default: ''
    },
  },
  data() {
    const viewMoreText = this.language?.SHEIN_KEY_PWA_21825
    const {
      props: { metaData: { templateType, productsNum = 4.2 } = {} } = {},
    } = this.propData || {}
    return {
      templateType,
      viewMoreText,
      productsNum: Number(productsNum),
      options: {
        direction: 'horizontal',
        slidesPerView: Number(productsNum),
        resistance: true, // 抵抗率, 能左右回弹
        virtualEnable: true, // 启用虚拟slides
        observeResize: true, // 监听resize，自动更新
        noPadding: true, // 商品占位图不需要左边距
      },
    }
  },
  computed: {
    analysisCode() {
      return `multi_line_${this.index + 1}_${this.containerIndex + 1}_1_${this.templateType}`
    },
    currentComponent() {
      if (this.templateType == CompTemplateType.shop) {
        return 'Shop'
      }
      return 'OtherTemplate'
    },
    productsList() {
      let { products = [], flashProducts = [] } = this.propData?.props?.metaData ?? {}
      if (this.templateType === 'superDeals') {
        products = [...flashProducts, ...products]
      }
      products = products?.slice(0, 10) // 取前10条数据，防止多余的数据
      return products
    },
  },
  methods: {
    resRefreshComp() {
      this.$refs.singleContainerSwiperRef?.swiperInstance?.slideTo?.(0, 0) // 刷新后，横滑位置需要返回到第一屏
      nextTick(() => {
        this.$refs.singleContainerSwiperRef?.updateVirtualSwiperInstance()
      })
    },
  },
})
</script>

<style lang="less">
.multi-line__single_content {
  .single-container__swiper {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    max-width: 100vw;
    &_slide {
      flex-shrink: 0;
      width: calc(100% / 4.2); // 默认4.2个商品
      border-right: 4px solid transparent;
    }
  }
  /* 保证商品项占位的宽度与商品一致 */
  .product-item__horizontal {
    padding-left: 0;
    .product-item__horizontal-item {
      margin: 0;
      border: none;
      flex-shrink: 0;
      border-right: 4px solid transparent;
    }
  }
}
</style>
