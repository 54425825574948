<!-- 品牌馆、品类周、自定义类型组件 -->
<template>
  <ProductItem
    :key="`${analysisCode}_${productItem?.goods_id}`"
    v-tap="getTapAnalysis(productItem, productItemIndex)"
    v-expose="getExposeAnalysis(productItem, productItemIndex)"
    :config="configComputed"
    :item="productItemComputed"
    :index="productItemIndex"
    :column="productsNum"
    :language="language"
    :show-view-more="showViewMore"
    :view-more-text="viewMoreText"
    :constant-data="constantData"
    :css-right="cssRight"
    :context="context"
    :crop-rate="cropRate"
    :lcp="isFirstPage && productItemIndex < 5"
    :comp-src="`multi-line-${templateType}`"
    :comp-img-design-width="192"
    :suggested-sale-type="suggestedSaleType"
    class="template-container__product-item"
    :price-font-size="priceFontSize"
    :class="{
      'product-simple__suggested_label': isShowLowestPriceLabelLang,
      'multi-line__superDeals_item': templateType === 'superDeals',
      'strengthen-price': templateType === 'superDeals' && isShowStrengthenPrice,
    }"
    @clickItem="handleClickItem"
    @viewMore="handleClickItem()"
  />
</template>

<script>
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { defineComponent } from 'vue'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin'
import useProductItemMixin from 'public/src/pages/components/ccc/components/mixins/use-product-item'
import { CompTemplateType } from '../constants.js'
// 简易商卡
import ProductItem from 'public/src/pages/components/ccc/home-bff-components/components/product-item-simple/index'

export default defineComponent({
  name: 'OtherTemplate',
  directives: {
    expose,
    tap,
  },
  components: {
    ProductItem,
  },
  mixins: [mixins, useProductItemMixin],
  props: {
    ...commonProps,
    isFirstPage: {
      type: Boolean,
      default: true,
    },
    // 商品项配置
    productItemConfig: {
      type: Object,
      default: () => {},
    },
    productsList: {
      type: Array,
      default: () => [],
    },
    productItem: {
      type: Object,
      default: () => {},
    },
    // 商品项索引
    productItemIndex: {
      type: Number,
      default: 0,
    },
    // 容器索引
    containerIndex: {
      type: Number,
      default: 0,
    },
    showViewMore: {
      type: Boolean,
      default: false,
    },
    viewMoreText: {
      type: String,
      default: '',
    },
    // 置顶商品abt
    homeAdpNum: {
      type: Number,
      default: 0
    },
    cssRight: {
      type: Boolean,
      default: false,
    },
    cropRate: {
      type: String,
      default: '3-4',
    },
    // 商品数量
    productsNum: {
      type: Number,
      default: 0,
    },
    // abt控制是否展示折扣标签，合规价
    homeDiscountNew: {
      type: String,
      default: '',
    },
    // 合规类型
    suggestedSaleType: {
      type: String,
      default: '',
    },
    // 埋点analysisCode
    analysisCode: {
      type: String,
      default: '',
    },
    // 不贵落地页早期tmg请求标志
    isEarlyRequest0903: {
      type: Boolean,
      default: false,
    },
    // 不贵是否加强价格
    showStrengthenPrice: {
      type: Boolean,
      default: false,
    },
    // 是否使用频道所对应的cate_id
    useFrontCateId: {
      type: Boolean,
      default: false,
    },
    // 趋势落地页参数判断
    earlyTmgRequest1210: {
      type: Boolean,
      default: false,
    },
    pindaoSkin: {
      type: String,
      default: '',
    },
    pindaoTrendsLook: {
      type: String,
      default: '',
    },
  },
  data() {
    const { metaData: { componentSize, templateType, priceFontSize = {}, mallCodes, } = {} } = this.propData?.props || {}
    return {
      componentSize: Number(componentSize),
      templateType,
      priceFontSize,
      mallCodes,
    }
  },
  computed: {
    cate() {
      switch (this.templateType) {
        case CompTemplateType.superDeals:
          let categoryIds = ''
          if (this.useFrontCateId) {
            // 前台类目 id
            categoryIds = this.context?.curChannelInfo?.tspNodeIds || ''
          } else {
            categoryIds = this.context?.curChannelInfo?.realCateIds || ''
          }
          return {
            markMap: this.propData?.props?.markMap, // 不使用解构，否则响应式会失效
            hrefType: 'thriftyFind',
            categoryIds,
            hrefTarget: this.context?.curChannelInfo?.tspNodeIds || '',
            mallCodes: this.productItem?.mall_code || '',
            compIndex: this.index,
            item_loc: `${this.containerIndex + 1}_${this.productItemIndex + 1}`,
            component_area: this.componentSize, // 组件面积
            template: this.templateType, // 组件模板
            content_type: {
              homeBelttype: this.productItem?.homeBelt?.type || this.productItem?.type || 'superSale',
              homeBelttext: this.productItem?.homeBelt?.text || '',
              homeBadgetype: 'discount',
              homeBadgetext: this.productItem?.homeBadge?.text || '',
            },
          }
        case CompTemplateType.trend:
          const info_flow = [
            this.index + 1,
            this.productItem.homeTrends?.contentCarrierId || '-',
            '-',
            `${this.productItemIndex + 1}_${this.productItem.goods_id}`,
            this.productItem?.homeTrends?.productSelectUrlId || '-',
            this.productItem?.homeTrends?.rec_mark || this.productItem.ext?.rec_mark || '-',
          ].join('`')
          let homeBadgetext = this.productItem?.homeBadge?.text || ''
          if (homeBadgetext && this.pindaoTrendsLook === 'C') {
            homeBadgetext = '' // 方案C，不展示趋势词。置空不上报
          }
          return {
            markMap: this.propData?.props?.markMap, // 不使用解构，否则响应式会失效
            hrefType: 'trendsChannel',
            compIndex: this.index,
            mallCodes: this.productItem?.mall_code || '',
            hrefTarget: this.context?.curChannelInfo?.realCateIds || '',
            item_loc: `${this.containerIndex + 1}_${this.productItemIndex + 1}`,
            component_area: this.componentSize, // 组件面积
            template: this.templateType, // 组件模板
            content_type: {
              homeBelttype: 'trend',
              homeBelttext: this.productItem?.homeBelt?.text || '',
              homeBadgetype: 'trends',
              homeBadgetext,
            },
            extData: { info_flow },
          }
        default:
          return {
            markMap: this.propData?.props?.markMap, // 不使用解构，否则响应式会失效
            hrefType: this.metaData.hrefType,
            hrefTarget: this.metaData.hrefTarget,
            item_loc: `${this.containerIndex + 1}_${this.productItemIndex + 1}`,
            component_area: this.componentSize, // 组件面积
            template: this.templateType, // 组件模板
            content_type: {
              homeBelttype: this.productItem?.homeBelt?.type || '',
              homeBelttext: this.productItem?.homeBelt?.text || '',
              homeBadgetype: this.productItem?.homeBadge?.type || '',
              homeBadgetext: this.productItem?.homeBadge?.text || '',
            },
          }
      }
    },
    isShowLowestPriceLabelLang() {
      if (
        this.componentSize != 1 ||
        this.homeDiscountNew !== 'compliance' ||
        !this.productItem?.suggestedSalePriceInfo?.description
      )
        return false
      return true
    },
    isShowStrengthenPrice() {
      if (this.componentSize != 1) {
        return false
      }
      // 展示合规价时不展示加强价格
      return (
        this.showStrengthenPrice &&
        this.homeDiscountNew !== 'noshow' &&
        this.homeDiscountNew !== 'compliance'
      )
    },
    // 商品项公共配置
    configComputed() {
      if (this.templateType === CompTemplateType.trend) {// https://arc.sheincorp.cn/docs/2681332321024206848
        return {
          ...this.productItemConfig,
          hideHomeBadge: this.pindaoTrendsLook, // 隐藏角标homeBadge
          hideHomeBelt: this.pindaoTrendsLook === 'B' || this.pindaoTrendsLook === 'C', // 隐藏腰带homeBelt
        }
      }
      return {
        ...this.productItemConfig,
      }
    },
    // 商品项
    productItemComputed() {
      // 趋势模板，显示和上报数据来源不同，特殊处理 https://arc.sheincorp.cn/docs/2681332321024206848
      if (this.templateType === CompTemplateType.trend) {
        const homeBelt = JSON.parse(JSON.stringify(this.productItem.homeBelt))// 深拷贝，避免影响原homeBelt
        if (this.pindaoTrendsLook) {
          if (this.productItem.homeBadge?.text) {
            homeBelt.text = this.productItem.homeBadge.text
            if (this.productItem.homeBadge?.textColor) {
              homeBelt.textColor = this.productItem.homeBadge.textColor
            }
            if (this.productItem.homeBadge?.icon) {
              homeBelt.icon = this.productItem.homeBadge.icon
            }
          }
        }
        return {
          ...this.productItem,
          homeBelt
        }
      }
      return {
        ...this.productItem,
      }
    },
  },
  methods: {
    handleClickItem(product) {
      switch (this.templateType) {
        case CompTemplateType.superDeals:
          // 不贵跳转
          this.handleSuperDealsJump(product)
          break
        case CompTemplateType.trend:
          //趋势跳转
          this.handleTrendJump(product)
          break
        case CompTemplateType.brand:
        case CompTemplateType.category:
        case CompTemplateType.custom:
          this.handleOtherTemplateJump(product)
          break
        default:
          break
      }
    },
    handleSuperDealsJump(product) {
      const { goods_id = '', type } = product || {}
      const params = {
        item: {
          ...this.metaData,
          hrefType: this.cate?.hrefType,
          hrefTarget: this.cate?.hrefTarget,
          item_loc: `${this.index}_${this.containerIndex}`,
          adp: '',
        },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: this.containerIndex,
        isBff: true,
      }
      // cccLink from mixin
      let url = this.cccLink.getFullLink(params)
      // 列表需要的参数
      const listView =
        window?.localStorage.getItem('super_deals_product_list_view') || ''
      const productsList = this.productsList.slice(0, this.homeAdpNum)
      let flashIds, superIds
      if (type == 'flashSale') {
        // 组装数据
        flashIds = [
          ...productsList
            .filter(i => i.type == 'flashSale')
            ?.map(i => i.goods_id)
            .filter(i => i !== goods_id),
        ].join(',')
        if (goods_id) {
          flashIds = flashIds ? `${goods_id},${flashIds}` : goods_id
        }
        superIds = productsList
          .filter(i => i.type != 'flashSale')
          ?.map(i => i.goods_id)
          .join(',')
      } else {
        // 组装数据
        superIds = [
          ...productsList
            .filter(i => i.type != 'flashSale')
            ?.map(i => i.goods_id)
            .filter(i => i !== goods_id),
        ].join(',')
        if (goods_id) {
          superIds = superIds ? `${goods_id},${superIds}` : goods_id
        }
        flashIds = productsList
          .filter(i => i.type == 'flashSale')
          ?.map(i => i.goods_id)
          .join(',')
      }
      let nodeId = '',
          realCateIds = ''
      switch (Number(this.metaData.categoryType)) {
        case 1:
          // 选中“默认”或“跟随频道”时，从频道获取前台类目；
          nodeId = this.context?.curChannelInfo?.tspNodeIds
          realCateIds = this.context?.curChannelInfo?.realCateIds || ''
          break
        case 3:
          // 选中“前台类目”时，优先使用组件配置的前台类目。前台类目为空时，从频道获取前台类目
          nodeId =
            this.metaData.frontCateIds ||
            this.context?.curChannelInfo?.tspNodeIds ||
            ''
          realCateIds =
            this.metaData.categoryIds ||
            this.context?.curChannelInfo?.realCateIds ||
            ''
          break
        // 选中“全品类”时，前台类目传空，获取全部商品
      }
      const adpStr = `adp=${superIds}&flash_adp=${flashIds}&cat_id=${realCateIds}&node_id=${nodeId}&pagefrom=page_home&list_view=${listView}`
      url = url.indexOf('?') > -1 ? `${url}&${adpStr}` : `${url}?${adpStr}`
      url = `${url}&eventTimestamp=${new Date().getTime()}`
      if (this.isEarlyRequest0903) {
        url = `${url}&isEarlyRequest0903=1`
      }
      if (this.mallCodes) {
        url = `${url}&mall_code=${this.mallCodes}`
      }
      this.jumpLink({
        url,
        hrefType: 'super-deals',
      })
    },
    // 跳转到趋势频道
    handleTrendJump(product) {
      let query = {
        page_from: [
          'page_home',
          this.tabIndex !== '' ? this.tabIndex + 1 : '-',
          this.context?.curChannelInfo?.tabName || '-',
          'FLEXIBLE_LAYOUT_FOR_SALEZONE',
        ].join('`'),
      }
      // 增加真实分类id
      this.context?.curChannelInfo?.realCateIds &&
        (query.catId = this.context?.curChannelInfo?.realCateIds || '')
      // 点击商品
      const currentGoods = product?.goods_id || ''
      const productsList = this.productsList.slice(0, this.homeAdpNum)
      let excludeGoods = []
      let arrList = productsList
        ?.filter(item => item?.goods_id !== currentGoods)
        ?.map(item => `${item?.homeTrends?.contentCarrierId}_${item?.goods_id}`)
      if (product?.homeTrends?.contentCarrierId && product?.goods_id) {
        excludeGoods.push(
          `${product?.homeTrends?.contentCarrierId}_${product?.goods_id}`,
        )
      }
      excludeGoods = excludeGoods.concat(arrList)
      if (excludeGoods?.length) {
        query.contentCarrierId_adp = excludeGoods.join(',')
      }
      const params = {
        item: {
          ...this.metaData,
          hrefType: this.cate?.hrefType,
          hrefTarget: this.context?.curChannelInfo?.realCateIds || '',
          item_loc: `${this.index}_${this.containerIndex}`,
          adp: '',
        },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: this.containerIndex,
        isBff: true,
      }
      // cccLink from mixin
      let url = this.cccLink.getFullLink(params)
      let queryString = Object.keys(query)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`,
        )
        .join('&')
      url =
        url.indexOf('?') > -1
          ? `${url}&${queryString}`
          : `${url}?${queryString}`
      if (!url || url.indexOf('javascript:;') > -1) return
      if (this.earlyTmgRequest1210) {
        url = `${url}&earlyTmgRequest1210=1`
      }
      this.jumpLink({ url, hrefType: this.cate?.hrefType })
    },
    handleOtherTemplateJump(product) {
      const { goods_id = '' } = product || {}
      // 将goods_id放到第一位
      const productList = this.productsList.slice(0, this.homeAdpNum)
      const goodsIdList = productList.map((i) => i.goods_id)
      const sortGoods = [...goodsIdList.filter(i => i !== goods_id)]
      const params = {
        item: {
          ...this.metaData,
          item_loc: `${this.index}_${this.containerIndex}`,
          adp: '',
        },
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: this.containerIndex,
        isBff: true,
      }
      // cccLink from mixin
      let url = this.cccLink.getFullLink(params)
      let goodsIds = sortGoods || ''
      if (!goodsIds) {
        const goodsIdList = this.productList.map(i => i.goods_id)
        goodsIds = goodsIdList.join(',')
      }
      if (goods_id) {
        goodsIds = `${goods_id},${goodsIds}`
      }
      if (this.metaData.hrefType !== 'webLink') {
        const adpStr = `adp=${goodsIds}`
        url = url.indexOf('?') > -1 ? `${url}&${adpStr}` : `${url}?${adpStr}`
      }
      this.jumpLink({ url, hrefType: this.metaData.hrefType })
    },
    getTapAnalysis(item, i) {
      return this.getProductAnalysis('2-22-4', {
        index: i,
        item,
        cate: this.cate,
        isJumpToList: true,
        useBffApi: true,
        setCode: `${this.analysisCode}_code`,
      })
    },
    getExposeAnalysis(item, i) {
      return this.getProductAnalysis('2-22-3', {
        type: 'expose',
        index: i,
        item,
        cate: this.cate,
        isJumpToList: true,
        useBffApi: true,
        setCode: `${this.analysisCode}_code`,
      })
    },
  },
})
</script>

<style lang="less" scoped>
.strengthen-price.simple-row-item {
  :deep(.product-card__bottom-wrapper) {
    padding-top: 2px;
  }
  :deep(.bff-price-container__top) {
    padding: 0 4px 0 8px;
    height: 100%;
    text-align: center;
  }
  :deep(.bff-price-container) {
    margin: 0 auto;
    min-width: 1.52rem;
    width: fit-content;
    max-width: calc(100% - 6px); // 避免100%,截断下降箭头
    min-height: unset;
    height: 18px;
    border-radius: 9px;
    background: linear-gradient(93.84deg, #ff3f3f 7.44%, #ff7144 97.19%);
    justify-content: center;
    position: relative;
    overflow: unset;
    padding: 0;
    .product-card__camel-case-price__placeholder {
      display: none !important; // 隐藏占位符，保持加强价格垂直居中
    }
    .prices-info__sale-price {
      width: 100%;
      color: #fff;
    }
    &::after {
      position: absolute;
      left: -3px;
      top: 45%;
      content: '';
      display: block;
      width: 12px;
      height: 16px;
      background-image: url('https://shein.ltwebstatic.com/svgicons/2024/07/18/17212901792622301071.svg');
      transform: translateY(-50%) scaleX(1) /* rtl: translateY(-50%) scaleX(-1) */;
    }
  }
}
</style>
